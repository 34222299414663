.check{
  width: px-to-em(24, 14);
  height: px-to-em(24, 14);
  position: absolute;
  right: px-to-em(20, 14);
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: color(Negative, Transparent, 100);
  &.valid {
    background: color(Positive, Transparent, 100);
  }
}