$large_button_font: 18;

%large-primary-main{
  font-size: px-to-rem($large_button_font, 16);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  color: color(White, Solid);
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  width: px-to-em(272, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: center;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Primary, Solid, 500);
  background: gradient(Primary, 500);
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  position: relative;
  overflow: hidden;
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 400); 
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    background: color(Primary, Transparent, 100);
    border: none;
    box-shadow: none;
    cursor: no-drop;
    text-shadow: unset;
  }
}

%large-primary-indicator{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Primary, Solid, 500);
  background: gradient(Primary, 500);
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
    padding-right: 10px;
  }
  .indicator{
    font-size: px-to-em(14, 17);
    padding: px-to-em(7, 14) px-to-em(17, 14);
    border-radius: px-to-em(40, 14);
    background: color(Black, Transparent, 100);
    display: inline-block;
    span {
      font-size: inherit;
      color: color(White, Solid, 500);
      &.primary{
        font-weight: 600;
      }
      &.secondary{
        font-weight: 300;
      }  
    }
  }
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 400);
    border-color: color(Primary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Primary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
    justify-content: center;
    // .text{
    //   display: none;
    // }
  }
}

%large-primary-outline{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Primary, Solid, 500);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  color: color(Primary, Solid, 500);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  text-transform: uppercase;
  transition: all 500ms;
  background: transparent;
  &:hover{
    border-color: color(Primary, Solid, 600);
    color: color(Primary, Solid, 600);
  }
  &.active{
    border-color: color(Primary, Solid, 400);
    color: color(Primary, Solid, 400);
  }
  &:disabled{
    border-color: color(Primary, Transparent, 100);
    color: color(Primary, Transparent, 100);
  }
}

%large-secondary-main{
  font-size: px-to-rem($large_button_font, 16);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  color: color(White, Solid);
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  width: px-to-em(272, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: center;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Secondary, Solid, 600);
  background: gradient(Secondary, 500);
  box-shadow: 0px 4px 16px rgba(139, 165, 1, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  &:hover{
    background: gradient(Secondary, 600);
    border-color: color(Secondary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(139, 165, 1, 0.4);
  }
  &.active{
    background: gradient(Secondary, 600);
    box-shadow: 0px 4px 8px rgba(139, 165, 1, 0.24);
  }
  &:disabled{
    background: color(Secondary, Transparent, 100);
    border: none;
    box-shadow: none;
    cursor: no-drop;
  }
}

%large-secondary-icon{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Secondary, Solid, 600);
  background: gradient(Secondary, 500);
  box-shadow: 0px 4px 16px rgba(139, 165, 1, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  }
  &:hover{
    background: gradient(Secondary, 600);
    box-shadow: 0px 4px 24px rgba(139, 165, 1, 0.4);
  }
  &.active{
    background: gradient(Secondary, 400);
    box-shadow: 0px 4px 8px rgba(139, 165, 1, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Secondary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
  }
}

%large-tertiary-indicator{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid #E4923C;
  background: gradient(Tertiary, 500);
  box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  }
  .indicator{
    font-size: px-to-em(14, $large_button_font);
    padding: px-to-em(7, 14) px-to-em($large_button_font, 14);
    border-radius: px-to-em(40, 14);
    background: color(White, Transparent, 100);
    display: inline-block;
    span {
      font-size: inherit;
      color: color(White, Solid, 500);
      &.primary{
        font-weight: 600;
      }
      &.secondary{
        font-weight: 300;
      }
    }
  }
  &:hover{
    background: gradient(Tertiary, 600);
    border-color: #D37F27;
    box-shadow: 0px 4px 24px rgba(204, 173, 32, 0.4);
  }
  &.active{
    background: gradient(Tertiary, 400);
    border-color: color(Tertiary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(204, 173, 32, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Tertiary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop; 
    opacity: 0.16;
  }
}

%large-positive-indicator{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, $large_button_font) solid color(Positive, Solid, 600);
  background: gradient(Positive, 500);
  box-shadow: 0px 4px 16px rgba(55, 146, 47, 0.32);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  }
  .indicator{
    font-size: px-to-em(14, $large_button_font);
    padding: px-to-em(7, 14) px-to-em($large_button_font, 14);
    border-radius: px-to-em(40, 14);
    background: color(White, Transparent, 100);
    display: inline-block;
    span {
      font-size: inherit;
      color: color(White, Solid, 500);
      &.primary{
        font-weight: 600;
      }
      &.secondary{
        font-weight: 300;
      }
    }
  }
  &:hover{
    background: gradient(Positive, 600);
    border-color: color(Positive, Solid, 600);
    box-shadow: 0px 4px 24px rgba(55, 146, 47, 0.4);
  }
  &.active{
    background: gradient(Positive, 400);
    border-color: color(Positive, Solid, 600);
    box-shadow: 0px 4px 8px rgba(55, 146, 47, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Positive, Transparent, 100);
    box-shadow: none;
    cursor: no-drop; 
    opacity: 0.16;
    .text{
      display: none;
    }
  }
}

%large-negative-outline{
  font-size: px-to-rem($large_button_font, 16);
  width: px-to-em(178, $large_button_font);
  height: px-to-em(56, $large_button_font);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(2, $large_button_font) solid color(Negative, Transparent, 400);
  border-radius: px-to-em(40, $large_button_font);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  color: color(Negative, Transparent, 400);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  text-transform: uppercase;
  transition: all 500ms;
  background: transparent;
  &:hover{
    border-color: color(Negative, Transparent, 500);
    color: color(Negative, Transparent, 500);
  }
  &.active{
    border-color: color(Negative, Transparent, 300);
    color: color(Negative, Transparent, 300);
  }
  &:disabled{
    border-color: color(Negative, Transparent, 100);
    color: color(Negative, Transparent, 100);
  }
}

%medium-primary-main{
  font-size: px-to-rem(14, 16);
  // // line-Height: px-to-em(17, 14);
  color: color(White, Solid, 500);
  font-weight: 700;
  width: px-to-em(64, 14);
  height: px-to-em(40, 14);
  border-radius: px-to-em(40, 14);
  background: gradient(Primary, 500);
  border: px-to-em(4, 14) solid color(Primary, Solid, 500);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  transition: all 500ms;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 400);
    border-color: color(Primary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    background: color(Primary, Solid, 100);
    border: none;
    box-shadow: none;
    cursor: no-drop;
  }
}

%medium-primary-indicator{
  font-size: px-to-rem(14, 16);
  width: px-to-em(128, 14);
  height: px-to-em(40, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, 14) solid color(Primary, Solid, 500);
  background: gradient(Primary, 500);
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  border-radius: px-to-em(40, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  }
  .indicator{
    font-size: px-to-em(14, 14);
    padding: px-to-em(7, 14) px-to-em(14, 14);
    border-radius: px-to-em(40, 14);
    background: color(Black, Transparent, 100);
    display: inline-block;
    span {
      font-size: inherit;
      color: color(White, Solid, 500);
      &.primary{
        font-weight: 600;
      }
      &.secondary{
        font-weight: 300;
      }  
    }
  }
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Primary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
    justify-content: center;
    .text{
      display: none;
    }
  }
}

%medium-light-main{
  font-size: px-to-rem(14, 16);
  // // line-Height: px-to-em(17, 14);
  color: color(Primary, Solid, 500);
  font-weight: 700;
  width: px-to-em(64, 14);
  height: px-to-em(40, 14);
  border-radius: px-to-em(40, 14);
  background: gradient(Light, 500);
  border: px-to-em(4, 14) solid color(Primary, Solid, 50);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
  transition: all 500ms;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    background: gradient(Light, 600);
    border-color: color(Primary, Solid, 100);
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.12);
  }
  &.active{
    background: gradient(Light, 400);
    border-color: color(Primary, Solid, 50);
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
  }
  &:disabled{
    background: color(Primary, Transparent, 25);
    border: none;
    box-shadow: none;
    cursor: no-drop;
    opacity: 0.5;
  }
}

%medium-light-round{
  font-size: px-to-rem(14, 16);
  // // line-Height: px-to-em(17, 14);
  color: color(Primary, Solid, 500);
  font-weight: 700;
  width: px-to-em(40, 14);
  height: px-to-em(40, 14);
  border-radius: px-to-em(40, 14);
  background: gradient(Light, 500);
  border: px-to-em(4, 14) solid color(Primary, Solid, 50);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
  transition: all 500ms;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  &:hover{
    background: gradient(Light, 600);
    border-color: color(Primary, Solid, 100);
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.12);
  }
  &.active{
    background: gradient(Light, 400);
    border-color: color(Primary, Solid, 50);
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
  }
  &:disabled{
    background: color(Primary, Transparent, 25);
    border: none;
    box-shadow: none;
    cursor: no-drop;
    opacity: 0.5;
  }
}

%medium-tertiary-indicator{
  font-size: px-to-rem(16, 16);
  // // line-Height: px-to-em(19, 16);
  color: color(White, Solid, 500);
  font-weight: 700;
  width: px-to-em(128, 16);
  height: px-to-em(40, 16);
  border-radius: px-to-em(40, 16);
  background: gradient(Tertiary , 500);
  border: px-to-em(2, 16) solid color(Tertiary, Solid, 500);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
  transition: all 500ms;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    background: gradient(Tertiary, 600);
    border-color: color(Tertiary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(204, 173, 32, 0.4);
  }
  &.active{
    background: gradient(Tertiary, 400);
    border-color: color(Tertiary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(204, 173, 32, 0.24);
  }
  &:disabled{
    background: gradient(Tertiary, 300);
    border: none;
    box-shadow: none;
    cursor: no-drop;
    opacity: 0.5;
  }
}

%medium-tertiary-round{
  font-size: px-to-rem(16, 16);
  // // line-Height: px-to-em(19, 16);
  color: color(White, Solid, 500);
  font-weight: 700;
  width: px-to-em(40, 16);
  height: px-to-em(40, 16);
  border-radius: px-to-em(40, 16);
  background: gradient(Tertiary , 500);
  border: px-to-em(4, 16) solid color(Tertiary, Solid, 500);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
  transition: all 500ms;
  // text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    background: gradient(Tertiary, 600);
    border-color: color(Tertiary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(204, 173, 32, 0.4);
  }
  &.active{
    background: gradient(Tertiary, 400);
    border-color: color(Tertiary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(204, 173, 32, 0.24);
  }
  &:disabled{
    background: color(Tertiary, Solid, 100);
    border: none;
    box-shadow: none;
    cursor: no-drop;
    opacity: 0.5;
  }
}

%social-button{
  font-size: px-to-rem(16, 16);
  width: px-to-em(40, 16);
  height: px-to-em(40, 16);
  border-radius: px-to-em(12, 16);
  background: color(Black, Transparent, 25);
  transition: all 500ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background: color(Black, Transparent, 100);
  }
  &.active{
    background: color(Black, Transparent, 50);
  }
  &:disabled{
    background: color(Black, Transparent, 25);
    opacity: 0.5;
    cursor: no-drop;
  }
}

%small-primary-main{
  font-size: px-to-rem(14, 16);
  // // line-Height: px-to-em(17, 14);
  font-weight: 700;
  text-transform: uppercase;
  width: px-to-em(96, 14);
  height: px-to-em(32, 14);
  border: px-to-em(2, 14) solid color(Primary, Solid, 500);
  background: gradient(Primary, 500);
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  border-radius: px-to-em(24, 14);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: color(White, Solid);
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 400);
    border-color: color(Primary, Solid, 500);
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    border: none;
    box-shadow: none;
    cursor: no-drop;
    background: color(Primary, Transparent, 100);
    opacity: 0.5;
  }
}

%small-secondary-icon{
  font-size: px-to-rem(14, 16);
  width: px-to-em(88, 14);
  height: px-to-em(32, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, 14) solid color(Secondary, Solid, 600);
  background: gradient(Secondary, 500);
  box-shadow: 0px 4px 16px rgba(139, 165, 1, 0.32);
  border-radius: px-to-em(24, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(17, 14);
    color: color(White, Solid);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  }
  &:hover{
    background: gradient(Secondary, 600);
    box-shadow: 0px 4px 24px rgba(139, 165, 1, 0.4);
  }
  &.active{
    background: gradient(Secondary, 400);
    box-shadow: 0px 4px 8px rgba(139, 165, 1, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Secondary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
  }
}

%small-positive-main{
  font-size: px-to-rem(14, 16);
  width: px-to-em(88, 14);
  height: px-to-em(32, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(4, 14) solid color(Positive, Solid, 600);
  background: gradient(Positive, 500);
  box-shadow: 0px 4px 16px rgba(55, 146, 47, 0.32);
  border-radius: px-to-em(24, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  font-weight: 700;
  // // line-Height: px-to-em(17, 14);
  color: color(White, Solid);
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
  &:hover{
    background: gradient(Positive, 600);
    border-color: color(Positive, Solid, 600);
    box-shadow: 0px 4px 24px rgba(55, 146, 47, 0.4);
  }
  &.active{
    background: gradient(Positive, 400);
    border-color: color(Positive, Solid, 600);
    box-shadow: 0px 4px 8px rgba(55, 146, 47, 0.24);
  }
  &:disabled{
    border: none;
    background: color(Positive, Transparent, 100);
    box-shadow: none;
    cursor: no-drop; 
    opacity: 0.16;
    .text{
      display: none;
    }
  }
}

%small-negative-outline{
  font-size: px-to-rem(14, 16);
  width: px-to-em(88, 14);
  height: px-to-em(32, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(2, 14) solid color(Negative, Transparent, 400);
  border-radius: px-to-em(24, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  color: color(Negative, Transparent, 400);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  text-transform: uppercase;
  transition: all 500ms;
  background: transparent;
  &:hover{
    border-color: color(Negative, Transparent, 500);
    color: color(Negative, Transparent, 500);
  }
  &.active{
    border-color: color(Negative, Transparent, 300);
    color: color(Negative, Transparent, 300);
  }
  &:disabled{
    border-color: color(Negative, Transparent, 100);
    color: color(Negative, Transparent, 100);
  }
}

%small-primary-outline{
  font-size: px-to-rem(14, 16);
  width: px-to-em(88, 14);
  height: px-to-em(32, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: px-to-em(2, 14) solid color(Primary, Solid, 500);
  border-radius: px-to-em(40, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  color: color(Primary, Solid, 500);
  font-weight: 700;
  // // line-Height: px-to-em(22, $large_button_font);
  text-transform: uppercase;
  transition: all 500ms;
  background: transparent;
  &:hover{
    border-color: color(Primary, Solid, 600);
    color: color(Primary, Solid, 600);
  }
  &.active{
    border-color: color(Primary, Solid, 400);
    color: color(Primary, Solid, 400);
  }
  &:disabled{
    border-color: color(Primary, Transparent, 100);
    color: color(Primary, Transparent, 100);
  }
}

%small-white-main{
  font-size: px-to-rem(14, 16);
  line-height: px-to-em(17, 14);
  color: color(Primary, Solid, 500);
  width: px-to-em(96, 14);
  height: px-to-em(32, 14);
  border-radius: px-to-em(40, 14);
  background: color(White, Transparent, 400);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background: color(White, Transparent, 200);
  }
  &.active{
    background: color(White, Solid, 500);
  }
  &:disabled{
    background: color(White, Transparent, 400);
    opacity: 0.5;
  }
}

%small-additional-main{
  font-size: px-to-rem(12, 16);
  line-height: px-to-em(17, 12);
  color: color(Primary, Solid, 500);
  width: px-to-em(64, 12);
  height: px-to-em(32, 12);
  border-radius: px-to-em(24, 12);
  background: color(Primary, Transparent, 50);
  display: flex;
  justify-content: center;
  align-items: center;
  // text-transform: capitalize;
  font-weight: 700;
  cursor: pointer;
  &:hover{
    background: color(Primary, Transparent, 100);
  }
  &.active{
    background: color(Primary, Solid, 25);
  }
  &:disabled{
    background: color(Primary, Transparent, 50);
    opacity: 0.5;
  }
}

%small-secondary-round{
  font-size: 1rem; //16px
  width: px-to-em(32, 16);
  height: px-to-em(32, 16);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: gradient(Secondary, 500);
  border: px-to-em(2, 16) solid color(Secondary, Solid, 500);
  box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.32);
  padding: 0px;
  cursor: pointer;
  &:hover{
    background: gradient(Secondary, 600);
    border-color: color(Secondary, Solid, 600);
    box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
  }
  &.active{
    background: gradient(Secondary, 400);
    border-color: color(Secondary, Solid, 400);
    box-shadow: 0px 4px 8px rgba(138, 163, 2, 0.24);
  }
  &:disabled{
    background: color(Secondary, Transparent, 100);
    border: none;
    box-shadow: none;
    opacity: 0.5;
  }
}

%small-tertiary-round{
  font-size: 1rem; //16px
  width: px-to-em(32, 16);
  height: px-to-em(32, 16);
  border-radius: 50%;
  line-height: px-to-em(32, 16);
  display: flex;
  justify-content: center;
  align-items: center;
  background: gradient(Tertiary, 500);
  border: px-to-em(2, 16) solid color(Tertiary, Solid, 500);
  box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    background: gradient(Tertiary, 600);
    border-color: #D37F27;
    box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.4);
  }
  &.active{
    background: gradient(Tertiary, 400);
    border-color: color(Tertiary, Solid, 400);
    box-shadow: 0px 4px 8px rgba(204, 173, 32, 0.24);
  }
  &:disabled{
    background: color(Tertiary, Transparent, 100);
    border: none;
    box-shadow: none;
    opacity: 0.5;
  }
}

%small-negative-round{
  font-size: 1rem; //16px
  width: px-to-em(32, 16);
  height: px-to-em(32, 16);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: gradient(Negative, 500);
  border: px-to-em(2, 16) solid color(Negative, Solid, 500);
  box-shadow: 0px 4px 16px rgba(190, 52, 66, 0.32);
  padding: 0px;
  cursor: pointer;
  &:hover{
    background: gradient(Negative, 600);
    border-color: color(Negative, Solid, 600);
    box-shadow: 0px 4px 16px rgba(190, 52, 66, 0.4);
  }
  &.active{
    background: gradient(Negative, 400);
    border-color: color(Negative, Solid, 400);
    box-shadow: 0px 4px 8px rgba(190, 52, 66, 0.24);
  }
  &:disabled{
    background: color(Negative, Transparent, 100);
    border: none;
    box-shadow: none;
    opacity: 0.5;
  }
}

%small-primary-round{
  font-size: 1rem; //16px
  width: px-to-em(32, 16);
  height: px-to-em(32, 16);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: gradient(Primary, 500);
  border: px-to-em(2, 16) solid color(Primary, Solid, 500);
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  padding: 0px;
  cursor: pointer;
  &:hover{
    background: gradient(Primary, 600);
    border-color: color(Primary, Solid, 600);
    box-shadow: 0px 4px 24px rgba(35, 71, 133, 0.4);
  }
  &.active{
    background: gradient(Primary, 400);
    border-color: color(Primary, Solid, 400);
    box-shadow: 0px 4px 8px rgba(35, 71, 133, 0.24);
  }
  &:disabled{
    background: color(Primary, Transparent, 100);
    border: none;
    box-shadow: none;
    opacity: 0.5;
    cursor: no-drop;
  }
}

%medium-negative-indicator{
  font-size: 18px;
  min-width: px-to-em(204, 18);
  height: px-to-em(56, 18);
  display: flex;
  justify-content: center;
  align-items: center;
  border: px-to-em(4, 18) solid color(Negative, Transparent, 400);
  background: transparent;
  box-shadow: 0px 4px 16px rgba(35, 71, 133, 0.32);
  border-radius: px-to-em(40, 18);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  .text{
    font-size: inherit;
    font-weight: 700;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(Negative, Transparent, 400);
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(51, 51, 51, 0.08);
    padding-right: 8px;
  }
  .indicator{
    font-size: px-to-em(14, 18);
    padding: px-to-em(7, 14) px-to-em(18, 14);
    border-radius: px-to-em(40, 14);
    background: color(Negative, Transparent, 50);
    display: inline-block;
    span {
      font-size: inherit;
      color: color(White, Solid, 500);
      &.primary{
        font-weight: 600;
      }
      &.secondary{
        font-weight: 300;
      }  
    }
  }
  &:hover{
    border-color: color(Negative, Transparent, 500);
    color: color(Negative, Transparent, 500);
  }
  &.active{
    border-color: color(Negative, Transparent, 300);
    color: color(Negative, Transparent, 300);
  }
  &:disabled{
    border-color: color(Negative, Transparent, 100);
    color: color(Negative, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
    opacity: 0.6;
    .text {
      color: color(Negative, Transparent, 100);
    }
  }
}