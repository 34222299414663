@import '../../functions';
@import '../../extends';
.privacy{
  position: absolute;
  bottom: 10px;
  left: 40px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(37, 40, 43, 0.64);
  &::first-letter{
    text-transform: capitalize;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.landing_page{
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  position: absolute;
  @media (max-width: 390px){
    top: 51vh;
  }
  @media (min-width: 391px) and (max-width: 992px){
    top: 58vh;
  }
  @media (orientation: landscape) {
    top: 30vh;
    @media (min-width: 992px) {
      top: 45vh;
    }
  }
  top: 30vh;
  right: 0;
  transform: translateY(-50%);
  .landing_container{
    width: 100%;
    .landing_top_content{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .landing_side{
        width: 100%;
        .information_outer_container{
          padding: 32px;
          background: color(White, Transparent, 400);
          border-radius: 24px;
        }
        .lading_side_content{
          width: auto;
          &.landing_left_content{
            padding: 0 0 0 px-to-em(48, 16);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .information {
              font-size: px-to-em(36, 16);
              max-width: px-to-em(605, 36);
              .page_title{
                font-family: 'FiraGO_Bold', sans-serif;
                width: 80%;
                font-size: 1em;
                color: color(Primary, Solid, 500);
                line-height: px-to-em(43, 36);
                font-weight: 700;
              }
              .page_description{
                width: 80%;
                font-size: px-to-em(18, 36);
                margin-top: px-to-em(24,18);
                line-height: px-to-em(22, 18);
                font-weight: 400;
                font-style: normal;
                color: color(Black, Solid, 300);
              }
            }
            .authorizaion{
              width: 100%;
              margin-top: px-to-em(32, 16);
              form{
                width: 100%;
                .form_group{
                  position: relative;
                  font-size: px-to-em(14, 16);
                  width: px-to-em(272, 14);
                  margin-top: px-to-em(16, 14);
                  &:last-child{
                    margin-top: 0;
                  }
                  &.form_text{
                    span{
                      font-size: 14px;
                      cursor: pointer;
                      line-height: inherit;
                      color: color(Primary, Solid, 500);
                      text-decoration: underline;
                      font-weight: 700;
                    }
                  }
                  &.buttons{
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: px-to-em(24, 16);
                    button {
                      margin-left: px-to-em(16, 18);
                      &:first-child{
                        margin-left: 0;
                      }
                    }
                    .log_in{
                      @extend %large-primary-main;
                      width: px-to-em(178, 18);
                      @media (orientation: landscape) and (max-width: 1400px) {
                        font-family: 'FiraGO_Bold', sans-serif;
                        font-size: 14px;
                      }
                    }
                    .sign_up{
                      @extend %large-primary-outline;
                      width: px-to-em(178, 18);
                      @media (orientation: landscape) and (max-width: 1400px) {
                        font-family: 'FiraGO_Bold', sans-serif;
                        font-size: 14px;
                      }
                    }
                  }
                  &.typeA{
                    min-width: 272px;
                  }
                }
              }
            }
          }
          &.landing_right_content{
            font-size: inherit; 
            width: px-to-em(605, 24);
            .information{
              width: 100%;
              .page_title {
                width: getPercent(400, 437);
                font-size: px-to-em(48, 24);
                font-weight: bold;
                line-height: px-to-em(58, 48);
                color: color(Primary, Solid, 500);
                margin-bottom: px-to-em(24, 48);
              }
              .page_description{
                font-size: 1em;
                width: 100%;
                color: color(Black, Solid, 400);
              }
            }
            .buttons{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: px-to-em(54, 24);
              button {
                margin-left: px-to-em(16, 18);
                &:first-child{
                  margin-left: 0;
                }                  
                &.log_in{
                  @extend %large-primary-outline;
                  width: px-to-em(178, 18);
                }
                &.sign_up{
                  @extend %large-primary-main;
                  width: px-to-em(178, 18);
                }
              }              
            }
          }
        }
        &.landing_right{
          width: 100%;
          box-sizing: border-box;
          font-size: px-to-em(24, 16);
          padding: 0 px-to-em(64, 24) 0 0 ;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
    .landing_informations_container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: px-to-em(18, 16);
      margin-top: px-to-em(120, 16);
      cursor: pointer;
      .landing_information_button{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #CDCDCD;
        width: calc((100% - #{px-to-em(36)} * 3) / 4);
        height: px-to-em(96, 18);
        border-radius: px-to-em(12, 18);
        .landing_information_button_text{
          font-size: 1em;
          line-height: px-to-em(22, 18);
          color: #333333;
          font-weight: 500;
        }
      }
    }
  }
  &.typeA{
    transform: translateY(-50%);
  }
  
  @media (max-width: 1366px) {
    font-size: 13px;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    .landing_container{
      .landing_top_content{
        .landing_right{
          padding-right: 0.5em!important;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    &.typeB{
      position: unset;
      transform: unset;
    }
    &.typeA{
      .landing_container{
        .landing_top_content{
          .landing_side{
            .landing_left_content{
              padding-left: 30px;
              .information{
                max-width: 30em;
                .information_container{
                  width: 100%;
                  max-width: 295px;
                }
              }
              .authorizaion{
                form{
                  .form_group{
                    width: 30em;
                  }
                }
              }
            }
          }
        }
      }
      @media (orientation: portrait) and (max-width: 992px){
        position: relative;
        .landing_container{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding-bottom: 20px;
          .landing_top_content{
            .landing_side{
              .landing_left_content{
                padding-left: 0;
                display: flex;
                justify-content: center;
                .information{
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  .page_title{
                    max-width: 295px;
                    width: auto;
                    text-align: center;
                  }
                  .page_description{
                    width: auto;
                    max-width: 295px;
                    text-align: center;
                  }
                }
                .authorizaion{
                  max-width: 312px;
                  form{
                    .form_group{
                      width: 30em;
                      width: 100%;
                      @media (min-width: 992px) {
                        display: none;  
                      }
                      @media(max-aspect-ratio: 15/16)  and (min-width: 992px){
                        display: block;
                      }
                      &.buttons{
                        flex-wrap: wrap;
                        button{
                          width: 100%!important;
                          margin: 0!important;
                          &:nth-child(2){
                            margin-top: 16px!important;
                          }
                        }
                      }  
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (orientation: portrait) and (max-width: 992px){
      .landing_container{
        .landing_top_content{
          .landing_right{
            justify-content: center!important;
            .landing_right_content{
              .information{
                .page_title{
                  margin-right: 0;
                  text-align: center;
                  width: 100%!important;
                }
                .page_description{
                  text-align: center;
                }
              }
              .buttons{
                justify-content: center!important;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    font-size: 10px;
    .landing_container{
      .landing_top_content{
        .landing_right{
          padding: 0!important;
          .landing_right_content{
            .buttons{
              margin-top: px-to-em(10, 16)!important;
              @media(orientation: landscape) {
                justify-content: flex-start!important;
              }
              button{
                height: 32px!important;
                font-size: 14px!important;
              }
            }
          }
        }
      }
    }
  }
  // All Phones
  @media (max-width: 768px) and (max-height: 1023px) {
    .landing_container{
      .landing_top_content{
        .landing_side{
          .landing_left_content{
            padding-left: 30px;
            .information{
              max-width: 30em;
            }
            .authorizaion{
              form{
                .form_group{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  // iPhone XR
  @media (max-width: 992px) and (orientation: landscape) {
    .landing_container{
      .landing_top_content{
        .landing_side{
          .landing_left_content{
            padding-left: 30px;
            .information{
              max-width: 30em;
            }
            .authorizaion{
              form{
                .form_group{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    &.typeA{
      @media (orientation: portrait) {
        .landing_container{
          .landing_top_content{
            .landing_side{
              .landing_left_content{
                .authorizaion{
                  max-width: 292px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.placeholder{
    position: unset;
    transform: unset;
  }
}

@media (max-width: 992px) {
  .mobile_only{
    position: fixed;
    bottom: 340px;
    left: 0;
    width: 100%;
    height: 30em;
    overflow-y: scroll;
  }
}

.cookiePopup_container{
  position: fixed;
  width: 60%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 2em;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 24px;
  .cookiePopup_innerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    .cookiePopup_dogImage{
      svg {
        width: 86.09px;
        height: 80px;
      }
    }
    .cookiePopup_title{
      display: none;
    }
    .cookiePopup_text{
      padding: 0em 1em;
      font-family: 'FiraGo_Regular', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #515355;
    }
    .cookiePopup_button{
      @extend %large-primary-main;
      padding: 0px 2em;
      width: 224px;
      height: 56px;
    }
  }
  
  @media (orientation: portrait) {
    // portrait desktop medium
    @media (max-width: 992px) {
      width: 90%;      
      .cookiePopup_text{
        font-size: 14px !important;
        line-height: 16.8px !important;
      }
      // portrait mobile OR portrait desktop small
      @media (max-height: 576px), (max-width: 576px) {
        bottom: 0;
        width: 100%;
        border-radius: 24px 24px 0 0;
        .cookiePopup_innerContainer{
          flex-direction: column;
          .cookiePopup_dogImage{
            svg{
              width: 172.19px;
              height: 160px;
            }
          }
          .cookiePopup_title{
            display: block;
            padding-top: 1em;
            font-family: 'FiraGo_Bold', sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #515355;
          }
          .cookiePopup_text{
            padding-top: 1em;
            padding-bottom: 3em;
            padding-left: 0;
            padding-right: 0;
            font-size: 14px;
            line-height: 16.8px;
            color: #515355;
          }
          .cookiePopup_button{
            margin-bottom: 1em;
            width: 100%;
            height: 56px;
          }
        }
      }
    }
  }

  // landscape mobiles and tablets
  @media (orientation: landscape) {
    @media  (max-width: 1200px) {
      .cookiePopup_innerContainer{
        .cookiePopup_text{
          font-size: 12px !important;
          line-height: 14.4px !important;
        }
        .cookiePopup_dogImage{
          svg{
            width: 68.88px !important;
            height: 64px !important;
          }
        }
        .cookiePopup_button{
          width: 129px;
          height: 40px;
          font-size: 14px;
          line-height: 17px;
          font-weight: 700;
        }
      }
    }
    @media  (max-width: 992px) {
      width: 90%;
      .cookiePopup_innerContainer{
        padding: 1em !important;
      }
    }
  }
}